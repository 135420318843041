<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <disorder-template :heading="$t('general')" :next="next" :sources="sources">
      <p v-for="paragraph in $t('disorders-general')" :key="paragraph">
        {{ paragraph }}
      </p>
    </disorder-template>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DisorderTemplate from "@/components/DisorderTemplate.vue";

export default {
  name: "general",
  components: {
    Breadcrumbs,
    DisorderTemplate
  },
  data: () => ({
    next: {
      slug: "anorexia",
      url: "/disorders/anorexia"
    },
    sources: [
      "[1] Eating Disorders (Taschenbuch), B. Timothy Walsh, Evelyn Attia, Deborah R. Glasofer. Accessed August 4, 2021. https://www.hugendubel.de/de/taschenbuch/b_timothy_walsh_evelyn_attia_deborah_r_glasofer-eating_disorders-37925247-produkt-details.html"
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("disorders"),
          disabled: true
        }
      ];
    }
  }
};
</script>
